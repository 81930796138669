
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import { mapActions, mapGetters } from "vuex";
import MessageBox from "@/components/MessageBox.vue";

// Services
import ScanService from "@/services/ScanService";
import LocationService from "@/services/LocationService";
import ActionService from "@/services/ShipService";
import PartsService from "@/services/PartsService";
import LotService from "@/services/LotInfoService";
import InventoryService from "@/services/inventory";

// Utlities
import Utils from "@/utility/utils";

const scanService = new ScanService(process.env.VUE_APP_ABSTRACTION_API);
const locationService = new LocationService(
  process.env.VUE_APP_ABSTRACTION_API,
);
const inventoryService = new InventoryService(
  process.env.VUE_APP_ABSTRACTION_API,
);
const lotService = new LotService(process.env.VUE_APP_ABSTRACTION_API);
const partsService = new PartsService();
const shipService = new ActionService(process.env.VUE_APP_ABSTRACTION_API);

export default defineComponent({
  name: "Details",
  components: {
    InputText,
    Button,
    MessageBox,
  },
  data() {
    return {
      pickShipPayload: {
        to_loc: "",
        ship_id: "",
        from_loc: "",
        from_bin: "",
        lot: "",
        part: "",
        quantity: "",
        update_ship: false,
        allow_new_quantity: false,
        user_id: "",
      },
      ship_pick_li: {} as any,
      lot_control: false,
      to_loc_bin_control: false,
      from_inv_bin_control: false,
      from_inv_neg_ok: false,
      from_inv_phys: false,
      fractions: false,
      balance: 0.0,
      loadingSaveButton: false,
      loadingExitButton: false,
      direct_flag: false,
      showIsShipComplete: false,
      showOpenItemList: false,
      showConfirmPickDone: false,
      showErrorDialog: false,
      showConfirmQuantity: false,
      messageConfirmQuantity: "",
      errorMessage: "",
      focusRefName: "",
      openItemListMessage: "",
      update_ship: false,
      rowClass:
        "p-field p-col col-12 m-0 p-0 pb-1 flex justify-content-center r-mono",
    };
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
      getUser: "session/getUser",
    }),
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
    }),
    clearAll() {
      this.pickShipPayload.to_loc = "";
      this.pickShipPayload.ship_id = "";
      this.pickShipPayload.from_loc = "";
      this.pickShipPayload.from_bin = "";
      this.pickShipPayload.lot = "";
      this.pickShipPayload.part = "";
      this.pickShipPayload.quantity = "";
      this.pickShipPayload.allow_new_quantity = false;
      this.pickShipPayload.update_ship = false;
      this.pickShipPayload.user_id = this.getUser.user_id;

      this.ship_pick_li = {};
      this.lot_control = false;
      this.from_inv_bin_control = false;
      this.from_inv_neg_ok = false;
      this.from_inv_phys = false;
      (this.fractions = false), (this.balance = 0.0);
    },
    clearSubmit() {
      this.pickShipPayload.lot = "";
      this.pickShipPayload.part = "";
      this.pickShipPayload.quantity = "";
      this.pickShipPayload.update_ship = false;

      this.ship_pick_li = {};
      this.lot_control = false;
      this.from_inv_bin_control = false;
      this.from_inv_neg_ok = false;
      this.from_inv_phys = false;
      (this.fractions = false), (this.balance = 0.0);
    },
    focusInput(refName: string) {
      if (refName) {
        (this.$refs[refName] as any).$el.focus();
        (this.$refs[refName] as any).$el.select();
      }
    },
    start() {
      var err = null;
      if (!this.pickShipPayload.to_loc) {
        err = "To location required.";
        this.focusInput("to_loc");
      }

      if (!this.pickShipPayload.ship_id) {
        err = "Ship ID required.";
        this.focusInput("ship_id");
      }

      if (!this.pickShipPayload.from_loc) {
        err = "From location required.";
        this.focusInput("from_loc");
      }

      if (err) {
        this.handleError(err);
        return;
      }

      if (err) {
        this.handleError(err);
        return;
      }

      this.loadingSaveButton = true;
      scanService
        .postPickShipment(this.pickShipPayload)
        .then((resp: any) => {
          this.handleSuccessDialog(
            resp?.message || "Successfully started PICK shipment.",
          );
          this.clearSubmit();
          this.focusInput("part");
        })
        .finally(() => {
          this.loadingSaveButton = false;
        });
    },
    handleToLocation() {
      if (this.pickShipPayload.to_loc) {
        locationService
          .getLocationById(
            this.getClient,
            this.pickShipPayload.to_loc,
            "invloc_id bin_control type",
          )
          .then((resp: any) => {
            if (resp.invloc_items && resp.invloc_items[0]) {
              const location = resp.invloc_items[0];
              this.pickShipPayload.to_loc = location.invloc_id;
              this.to_loc_bin_control = location.bin_control === "Y";

              if (location.type === "WO") {
                throw new Error("WIP location not allowed");
              }

              if (this.pickShipPayload.ship_id) {
                shipService
                  .getShipById(
                    this.getClient,
                    this.pickShipPayload.ship_id,
                    "ship_id pull_loc pack_pull_date",
                  )
                  .then((resp: any) => {
                    if (resp && resp[0]) {
                      if (resp.pull_loc === "SHIP" || resp.pack_pull_date) {
                        throw new Error(
                          "Shipment has finished the PICK/PULL step or already started the Pack process.",
                        );
                      }
                    }
                  });
              }
              this.focusInput("ship_id");
            } else {
              throw new Error(
                this.pickShipPayload.to_loc +
                  " is not a valid inventory location",
              );
            }
          })
          .catch((err) => {
            console.log(err);
            this.pickShipPayload.to_loc = "";
            this.to_loc_bin_control = false;
            this.handleErrorAndFocus(
              err.toString() || "Error with To Location.",
              "to_loc",
            );
          });
      } else {
        this.handleErrorAndFocus("To location required.", "to_loc");
      }
    },
    handleShipId() {
      if (this.pickShipPayload.ship_id) {
        const fieldNames =
          "ship_id status pull_date pull_loc pack_pull_date pick_li pick_part pick_ship_qty pick_location pick_qty_pulled pack_scan_qty";
        shipService
          .getShipById(this.getClient, this.pickShipPayload.ship_id, fieldNames)
          .then((resp: any) => {
            if (resp && resp.ship_items && resp.ship_items.length > 0) {
              const ship_item = resp.ship_items[0];
              this.pickShipPayload.ship_id = ship_item.ship_id;

              if (ship_item.status != "N") {
                throw new Error("Shipment has already been shipped.");
              }

              if (ship_item.pull_date) {
                throw new Error("Shipment has already been pulled.");
              }

              if (ship_item.pack_pull_date) {
                throw new Error("Shipment has already been scanned into PACK.");
              }

              if (ship_item.pull_loc === "SHIP") {
                throw new Error("Shipment has already begun the Pack process.");
              }

              if (
                ship_item.pull_loc != null &&
                ship_item.pull_loc != this.pickShipPayload.to_loc
              ) {
                throw new Error(
                  `Shipment pull to location has already been set to ${ship_item.pull_loc}.`,
                );
              }

              this.ship_pick_li = ship_item.pick_li_items;

              this.focusInput("from_loc");
            } else {
              throw new Error(
                this.pickShipPayload.ship_id + " is not a valid shipment..",
              );
            }
          })
          .catch((err) => {
            this.pickShipPayload.ship_id = "";
            this.handleErrorAndFocus(
              err.toString() || "Error with Ship ID.",
              "ship_id",
            );
          });
      }
    },
    handleFromLocation() {
      if (this.pickShipPayload.from_loc) {
        const [from_loc, bin] = Utils.splitStringInTwo(
          this.pickShipPayload.from_loc,
          "-",
        );
        this.pickShipPayload.from_loc = from_loc;
        this.pickShipPayload.from_bin = bin;

        locationService
          .getLocationById(
            this.getClient,
            this.pickShipPayload.from_loc,
            "invloc_id bin_control type neg_ok",
          )
          .then((resp: any) => {
            if (resp.invloc_items && resp.invloc_items[0]) {
              const location = resp.invloc_items[0];
              this.pickShipPayload.from_loc = location.invloc_id;
              this.from_inv_bin_control = location.bin_control === "Y";
              this.from_inv_neg_ok = location.neg_ok === "Y";
              this.from_inv_phys = location.phys === "Y";

              if (location.type === "WO") {
                throw new Error("WIP location not allowed");
              }

              if (!this.from_inv_bin_control) {
                this.pickShipPayload.from_bin = "";
                this.focusInput("part");
                return;
              }

              if (this.from_inv_bin_control && bin) {
                this.focusInput("part");
                return;
              }

              this.focusInput("from_bin");
            } else {
              throw new Error(from_loc + " is not a valid inventory location");
            }
          })
          .catch((err) => {
            console.log(err);
            this.pickShipPayload.from_loc = "";
            this.pickShipPayload.from_bin = "";
            this.handleErrorAndFocus(
              err.toString() || "Error with From Location.",
              "from_loc",
            );
          });
      } else {
        this.handleErrorAndFocus(
          "An inventory location must be specified.",
          "from_loc",
        );
      }
    },
    handleFromBin() {
      if (!this.from_inv_bin_control) {
        const message =
          this.pickShipPayload.from_loc + " is not a bin controlled location.";
        this.handleErrorAndFocus(message, "from_bin");
        this.pickShipPayload.from_bin = "";
      } else if (!this.pickShipPayload.from_bin) {
        const message = this.pickShipPayload.from_loc + "Bin number required.";
        this.handleErrorAndFocus(message, "from_bin");
        this.pickShipPayload.from_bin = "";
      } else {
        this.focusInput("part");
      }
    },
    handlePart() {
      if (this.pickShipPayload.part) {
        partsService
          .getPartByID({
            client: this.getClient,
            id: this.pickShipPayload.part,
            fieldnames: "part_no lot_control fractions",
          })
          .then((resp: any) => {
            this.pickShipPayload.part = resp.part_no;
            this.lot_control = resp.lot_control === "Y";
            this.fractions = resp.fractions === "Y";
            if (!this.lot_control) {
              this.focusInput("quantity");
            } else {
              this.focusInput("lot");
            }

            let found = false;

            const pick_li = this.ship_pick_li.find(
              (pick_li: any) => pick_li.pick_part === this.pickShipPayload.part,
            );

            const validLocations = ["NFDG", "FG1", "FG", "VIASTG"];

            if (
              pick_li.pick_location === this.pickShipPayload.from_loc ||
              validLocations.includes(this.pickShipPayload.from_loc)
            ) {
              const req_qty = parseFloat(pick_li.pick_ship_qty) || 0.0;
              let scan_qty = 0;
              pick_li.pick_qty_pulled_items.forEach((item: any) => {
                scan_qty += parseFloat(item.pack_scan_qty) || 0.0;
              });

              if (req_qty > scan_qty) {
                this.balance = req_qty - scan_qty;
                found = true;
              } else {
                this.balance = 0;
              }
            }

            if (!found) {
              if (!this.balance) {
                throw new Error(
                  "Part number and location not found on shipment.",
                );
              } else {
                throw new Error("No balance left to scan for this part.");
              }
            }
          })
          .catch((err) => {
            const message =
              err.response.data.message ||
              this.pickShipPayload.part + " is not a valid part number.";
            this.handleErrorAndFocus(message, "part");
          });
      } else {
        this.handleErrorAndFocus("Part number required.", "part");
      }
    },
    handleLot() {
      if (this.lot_control) {
        if (this.pickShipPayload.lot) {
          lotService
            .getLot(this.pickShipPayload.lot, "lot_number part_number")
            .then((resp: any) => {
              this.pickShipPayload.lot = resp.lot_number;
              if (this.pickShipPayload.part != resp.part_number) {
                throw new Error(
                  "Lot number " +
                    this.pickShipPayload.part +
                    " is for part " +
                    resp.part_number,
                );
              }
            })
            .catch((err) => {
              const message =
                err || this.pickShipPayload.lot + " is not a valid Lot.";
              this.handleErrorAndFocus(message, "lot");
            });
        } else {
          this.handleErrorAndFocus("Lot number required.", "lot");
        }
      } else {
        const message = this.pickShipPayload.part + " is not lot controlled";
        this.pickShipPayload.lot = "";
        this.handleErrorAndFocus(message, "lot");
      }
    },
    handleQuantity() {
      if (!this.fractions) {
        if (!Number.isInteger(+this.pickShipPayload.quantity)) {
          this.handleErrorAndFocus("Fractions are not permitted", "quantity");
          return;
        }
      }

      if (+this.pickShipPayload.quantity > 0) {
        if (+this.pickShipPayload.quantity > this.balance) {
          this.handleErrorAndFocus(
            "Quantity exceeds balance to pull for this part.",
            "quantity",
          );
          return;
        }

        if (this.from_inv_bin_control && !this.from_inv_neg_ok) {
          inventoryService
            .getInventoryById(
              this.getClient,
              this.pickShipPayload.part,
              "part_no invloc bin bin_qty",
              "",
            )
            .then((resp: any) => {
              if (!resp.inv_items || resp.inv_items.length === 0) {
                throw new Error("No inventory record found.");
              }

              const inv_item = resp.inv_items[0];

              if (
                !inv_item.invloc_items ||
                inv_item.invloc_items.length === 0
              ) {
                throw new Error("No inventory record found.");
              }

              const invloc = inv_item.invloc_items.find(
                (item: any) => item.invloc === this.pickShipPayload.from_loc,
              );

              if (invloc) {
                const bin = invloc.bin_items.find(
                  (item: any) => item.bin === this.pickShipPayload.from_bin,
                );
                if (bin) {
                  if (
                    +this.pickShipPayload.quantity > parseFloat(bin.bin_qty)
                  ) {
                    this.handleError("Insufficient quantity in bin.");
                  }
                } else {
                  this.handleError(
                    "Bin not found for location " +
                      this.pickShipPayload.from_loc,
                  );
                }

                if (this.from_inv_phys) {
                  if (this.lot_control) {
                    console.log("need to add lot control check here");
                  } else {
                    let on_hand = 0;
                    if (invloc.on_hand) {
                      on_hand = parseFloat(invloc.on_hand) || 0.0;
                    }

                    if (+this.pickShipPayload.quantity > 0) {
                      if (+this.pickShipPayload.quantity > on_hand) {
                        if (!this.from_inv_neg_ok) {
                          this.handleError(
                            "The on-hand balance is '" + on_hand + ".",
                          );
                        } else {
                          const new_quantity =
                            on_hand - +this.pickShipPayload.quantity;
                          this.messageConfirmQuantity = `Balance will become ${new_quantity}. Okay?`;
                          this.showConfirmQuantity = true;
                        }
                      }
                    }
                  }
                }
              } else {
                this.handleError("Inventory location not found.");
              }
            })
            .catch((err) => {
              this.handleError("No inventory record found.");
            });
        }
      }
    },
    clickedOpenItemList(response: boolean) {
      if (response) {
        this.focusInput("part");
      } else {
        this.showConfirmPickDone = true;
      }
      this.showOpenItemList = false;
    },
    clickedConfirmPickDone(response: boolean) {
      if (response) {
        this.update_ship = true;
      }
      this.showConfirmPickDone = false;
    },
    clickedConfirmQuantity(response: boolean) {
      if (response) {
        this.pickShipPayload.allow_new_quantity = true;
      }
      this.showConfirmQuantity = false;
    },
    clickedIsShipComplete(response: boolean) {
      if (response) {
        const fieldNames =
          "ship_id status pull_date pull_loc pack_pull_date pick_li pick_part pick_ship_qty pick_location pick_qty_pulled pack_scan_qty";
        shipService
          .getShipById(this.getClient, this.pickShipPayload.ship_id, fieldNames)
          .then((resp: any) => {
            if (resp && resp.ship_items && resp.ship_items.length > 0) {
              const ship_item: any = resp.ship_items[0];
              this.pickShipPayload.ship_id = ship_item.ship_id;
              const open_li_list: any[] = [];

              ship_item.pick_li_items.forEach((pick_li: any) => {
                const req_qty = parseFloat(pick_li.pick_ship_qty) || 0.0;
                let scan_qty = 0;
                pick_li.pick_qty_pulled_items.forEach((item: any) => {
                  scan_qty += parseFloat(item.pick_qty_pulled);
                });

                if (req_qty > scan_qty) {
                  open_li_list.push(
                    pick_li.pick_li + " - " + pick_li.pick_part,
                  );
                }
              });

              if (open_li_list.length > 0) {
                this.showOpenItemList = true;
                this.openItemListMessage =
                  "The following line items have not been scanned:\r\n";
                this.openItemListMessage += open_li_list.join("\r\n");
                this.openItemListMessage +=
                  "\r\n\r\nDo you want to continue scanning?";
              } else {
                this.update_ship = true;
              }
            }
          });
      }
      this.showIsShipComplete = false;
    },
    handleExitTime() {
      if (!this.pickShipPayload.ship_id) {
        this.$router.push("/scan");
      } else {
        this.showIsShipComplete = true;
      }
    },
    handleError(message: string) {
      const notification = {
        dialog: true,
        type: "error",
        message,
      };
      this.addNotification(notification, { root: true });
    },
    handleErrorAndFocus(message: string, ref: string) {
      this.errorMessage = message;
      this.showErrorDialog = true;
      this.focusRefName = ref;
    },
    clickConfirmErrorDialog() {
      this.showErrorDialog = false;
      if (this.focusRefName) {
        this.focusInput(this.focusRefName);
      }
      this.focusRefName = "";
    },
    handleSuccessDialog(message: string) {
      const notification = {
        dialog: true,
        type: "success",
        message,
      };
      this.addNotification(notification, { root: true });
    },
    updateShip() {
      this.pickShipPayload.update_ship = true;
      this.loadingExitButton = true;
      scanService
        .postPickShipment(this.pickShipPayload)
        .then((resp: any) => {
          this.handleSuccessDialog(
            resp?.message || "Successfully updated shipment.",
          );
          this.$router.push("/scan");
        })
        .finally(() => {
          this.loadingExitButton = false;
          this.pickShipPayload.update_ship = false;
        });
    },
  },
  mounted() {
    this.clearAll();
    this.pickShipPayload.to_loc = "PACK";
    this.handleToLocation();
  },
  watch: {
    update_ship(ship) {
      if (ship) {
        this.updateShip();
        this.update_ship = false;
      }
    },
  },
});
